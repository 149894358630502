import { first } from "rxjs/operators";
import {
  Component,
  OnInit,
  Directive, Input, ElementRef, Renderer2,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { AuthenticationService } from "providers/authentication.service";
import { ConfigService } from "providers/config/config.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { EventEmitterService } from "providers/event-emitter/event-emitter.service";
import { joyrideStepsContent,AppTourService } from "providers/app-tour/app-tour";
import { Capacitor } from "@capacitor/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
// import { stepsContent } from "../navbar/navbar.component";

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  // icon: string;
  children?: ChildrenItems[];
  step?:string;
  stepcontent?:string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin",
    title: "Administration Centre",
    type: "",
    icontype: "fas fa-user-shield",
  },
  {
    path: "/home",
    title: "Home",
    type: "link",
    icontype: "fas fa-home",
    step: "56",
    stepcontent: "View feed by on taping home icon",
  },
  {
    path: "/my-client-portal",
    title: "Client Portal",
    type: "link",
    icontype: "fab fa-trello",
    step: "57",
    stepcontent: "Access client portal",
  },
  {
    path: "/chats",
    title: "Chat",
    type: "",
    icontype: "fas fa-comments-alt",
    step: "58",
    stepcontent: "Access chats",
  },
  {
    path: "/my-coaches",
    title: "My Coaches",
    type: "",
    
    icontype: "fas fa-users-crown",
    
  },
  {
    path: "/my-clients",
    title: "My Clients",
    type: "",
    step: "59",
    stepcontent: "Access your connections",
    icontype: "fas fa-users",
  },
  {
    path: "/coaching",
    title: "Coaching",
    type: "",
    icontype: "fas fa-users-class",
  },
  // {
  //   path: "/all-my-programs",
  //   title: "All My Programs",
  //   type: "",
  //   // icontype: 'fas fa-running'
  //   icontype: "fas fa-th-list",
  // },
  {
    path: "/client-coaching",
    title: "Client Coaching",
    type: "",
    step: "60",
    stepcontent: "Access your coaching options",
    // icontype: 'fas fa-running'
    icontype: "fas fa-users-class",
  },
  {
    path: "/fit-camp",
    title: "Fit Camps",
    type: "link",
    step: "61",
    stepcontent: "Access fit camps",
    icontype: "fas fa-dumbbell",
  },
  {
    path: "/video-library",
    title: "Video Library",
    type: "link",
    step: "63",
    stepcontent: "Access video library",
    icontype: "fas fa-photo-video",
  },
  {
    path: "/membership",
    title: "Membership",
    type: "link",
    icontype: "fas fa-address-card",
  },
  {
    path: "/tdee",
    title: "TDEE Calculator",
    type: "link",
    step: "64",
    stepcontent: "Access TDEE Calculator",
    icontype: "fab fa-nutritionix",
  },
  // One Rep Max Calculator
  {
    path: "/1rm",
    title: "One Rep Max",
    type: "link",
    icontype: "fas fa-calculator",
  },
  {
    path: "/calendar",
    title: "Calendar",
    type: "link",
    icontype: "fas fa-calendar-day",
  },
  {
    path: "/profile",
    title: "My Profile",
    type: "link",
    icontype: "fas fa-user",
  },
  {
    path: "/my-testimonies",
    title: "All My Testimonies",
    type: "link",
    icontype: "fas fa-stars",
  },
  {
    path: "/private-community",
    title: "Private Community",
    type: "link",
    icontype: "fa fa-user-secret",
  },
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  styleUrls: ["./sidebar.component.css"],
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: RouteInfo[] = [];
  isMobile: boolean = false;
  toggleButton: any;
  sidebarVisible: boolean;
  currentStep: any;
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  @ViewChild(NgbDropdown, { static: true })
  public dropdown: NgbDropdown;

  private subscriptionUser: Subscription;
  private _serviceNotificationSubscription: Subscription;

  isThereChat: boolean = false;

  user = {
    id: "",
    token: "",
    fullName: "",
    email: "",
    avatar: "",
    publicid: "",
    notification: null,
    emailVerification: null,
    profileCompletition: 0,
  };

  stepsContent: string[] = joyrideStepsContent;

  platform: string = Capacitor.getPlatform();

  constructor(
    private _authService: AuthenticationService,
    private emitterService: EventEmitterService,
    private readonly _configService: ConfigService,
    private AppTourService: AppTourService
  ) {
    this._serviceNotificationSubscription =
      this.emitterService.eventOnChange.subscribe({
        next: async (event: string) => {
          if (event == "updateNotification")
            setTimeout(async () => {
              await this.checkIfThereIsAChat();
            }, 1000);
        },
      });
  }

  async ngOnInit() {
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);

    this.subscriptionUser = this._authService.authUserChange.subscribe(
      async (value) => {
        this.menuItems = ROUTES.filter((menuItem) => menuItem);

        let user: any[] = value ? JSON.parse(value) : {};
        this.user.id = user["_id"];
        this.user.token = user["token"];
        this.user.fullName = user["name"] + " " + user["surname"];
        this.user.email = user["email"];
        this.user.emailVerification = user["emailVerification"];
        this.user.publicid = user["publicid"];

        const userRole = user["role"];
        
        
        if (user["stats"])
          this.user.profileCompletition = user["stats"].profileCompletition;

        const objIndexAdmin = this.menuItems.findIndex(
          (obj) => obj.path == "/admin"
        );
        if (userRole == "Admin") this.menuItems[objIndexAdmin].type = "link";
        else this.menuItems[objIndexAdmin].type = "";

        const objIndexMyCoach = this.menuItems.findIndex(
          (obj) => obj.path == "/my-coaches"
        );
        const objIndexClientCoaching = this.menuItems.findIndex(
          (obj) => obj.path == "/client-coaching"
        );

        if (userRole == "User") {
          this.menuItems[objIndexMyCoach].type = "link";
          this.menuItems[objIndexClientCoaching].type = "link";
        } else {
          this.menuItems[objIndexMyCoach].type = "";
          this.menuItems[objIndexClientCoaching].type = "";
        }

        const objIndexCoaching = this.menuItems.findIndex(
          (obj) => obj.path == "/coaching"
        );
        const objIndexMyClients = this.menuItems.findIndex(
          (obj) => obj.path == "/my-clients"
        );
        // const objIndexMembership = this.menuItems.findIndex(
        //   (obj) => obj.path == "/membership"
        // );
        if (userRole != "User") {
          this.menuItems[objIndexCoaching].type = "link";
          this.menuItems[objIndexMyClients].type = "link";
          // this.menuItems[objIndexMembership].type = "link";
        } else {
          this.menuItems[objIndexCoaching].type = "";
          this.menuItems[objIndexMyClients].type = "";
          // this.menuItems[objIndexMembership].type = "";
        }

        const objIndexChats = this.menuItems.findIndex(
          (obj) => obj.path == "/chats"
        );

        if (user["friends"].length > 0)
          this.menuItems[objIndexChats].type = "link";
        else this.menuItems[objIndexChats].type = "";

        const objIndexMyProfile = this.menuItems.findIndex(
          (obj) => obj.path == "/profile"
        );
        if (objIndexMyProfile != -1)
          this.menuItems[objIndexMyProfile].path =
            "/profile/" + this.user.publicid;

        const objIndexMembership = this.menuItems.findIndex(
          (obj) => obj.path == "/membership"
        );

        if(this.platform != "web"){
          this.menuItems.splice(objIndexMembership, 1);
        }

        if (window.innerWidth < 489) {
          this.isMobile = true
        }

        this.getStepPosition()
        
        this.user.avatar = this._configService.url + user["avatar"];
      }
    );

    await this.checkIfThereIsAChat();
  }

  getStepPosition(): string {
    return this.isMobile ? 'center' : 'right';
  }

  transform(value: string) {
    let now = moment(value).fromNow();
    return now;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");
        misc.sidebar_mini_active = true;
      }, 300);
    }


    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarClose() {
    var html = document.getElementsByTagName("html")[0];
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 1000);
    }

    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }



  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
    this._serviceNotificationSubscription.unsubscribe();
  }

  async checkIfThereIsAChat() {
    const userVm = {
      id: this.user.id,
      token: this.user.token,
    };

    await (await this._authService.checkIfThereIsAChat(userVm))
      .pipe(first())
      .subscribe(
        async (data) => {
          this.isThereChat = data;
        },
        async (err) => {}
      );
  }

  minimizeonlyformobile(){
    this.currentStep = this.AppTourService.currentStep;
    if(this.currentStep === "step64"){
    if(window.innerWidth <= 786){
      this.sidebarClose();
    }
  }
  }

  
  
}
