import { first } from "rxjs/operators";
import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Directive,
  ViewChildren,
  QueryList,
  EventEmitter,
} from "@angular/core";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { Subscription } from "rxjs/Subscription";
import { ROUTES } from "../sidebar/sidebar.component";
import { AuthenticationService } from "providers/authentication.service";
import { ConfigService } from "providers/config/config.service";
import { NOTIFICATION } from "app/pages/app-area/user-notifications/user-notifications.component";
import * as moment from "moment";
import * as schedule from "node-schedule";
import { EventEmitterService } from "providers/event-emitter/event-emitter.service";
import Swal from "sweetalert2";
import { JoyrideService } from "ngx-joyride";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { UserMembership } from "app/configurations/dropdownConfigurations";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { StoreProvider } from "providers/store/store";
import {
  AppTourService,
  joyrideStepsContent,
} from "providers/app-tour/app-tour";
import { timer } from "rxjs";

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

@Component({
  moduleId: module.id,
  selector: "navbar-cmp",
  styleUrls: ["./navbar.component.css"],
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public open: boolean = false;
  isDropdownOpen: boolean = false;

  @ViewChild("navbar-cmp", { static: false }) button;

  private subscriptionUser: Subscription;
  private _serviceNotificationSubscription: Subscription;

  allNotificationsRecords: any;
  notificationsRecords: NOTIFICATION[] = [];
  searchtext: string = "";
  // @ViewChildren("search") search: QueryList<ElementRef>;
  @ViewChild("search") search: ElementRef;

  user = {
    id: "",
    token: "",
    name: "",
    fullName: "",
    email: "",
    avatar: "",
    publicid: "",
    notification: 0,
    emailVerification: null,
    role: "",
  };

  Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  currentStep: string = null;

  membership: UserMembership = UserMembership.Free;

  @ViewChild(NgbDropdown, { static: true })
  public dropdown: NgbDropdown;

  // open dropdown
  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private _authService: AuthenticationService,
    private emitterService: EventEmitterService,
    private readonly _configService: ConfigService,
    private AppTourService: AppTourService // private storage: StoreProvider,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this._serviceNotificationSubscription =
      this.emitterService.eventOnChange.subscribe({
        next: (event: string) => {
          if (event == "updateNotification") this.getAllNotifications();
        },
      });
  }

  async ngOnInit() {
    var rule = new schedule.RecurrenceRule();
    // rule.minute = new schedule.Range(0, 0, 20);
    rule.second = 0;

    schedule.scheduleJob(rule, () => {
      if (this._authService.isAuthorized())
        this.emitterService.eventOnChange.emit("updateNotification");
    });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });

    this.subscriptionUser = this._authService.authUserChange.subscribe(
      async (value) => {
        let user: any[] = value ? JSON.parse(value) : {};
        this.user.id = user["_id"];
        this.user.token = user["token"];
        this.user.name = user["name"];
        this.user.fullName = user["name"] + " " + user["surname"];
        this.user.email = user["email"];
        this.user.emailVerification = user["emailVerification"];
        this.user.publicid = user["publicid"];
        this.user.role = user["role"];
        this.membership = user["membership"];
        // if (this.user.emailVerification) {
        //     this.privilege = user['privilege'];
        //     if (user['role'] == 'Admin') this.admin = true;
        //     else this.admin = false;
        // }

        // this.showUnverifiedEmail = !this.user.emailVerification;
        this.user.avatar = this._configService.url + user["avatar"];
      }
    );

    this._authService.authUserNotifications.subscribe((value) => {
      this.notificationsRecords = [];
      let notifications: any[] = value ? JSON.parse(value) : null;
      if (notifications) {
        this.allNotificationsRecords = notifications["notifications"];
        if (notifications["totalNotification"] != 0)
          this.user.notification = notifications["totalNotifications"];
        else this.user.notification = null;

        this.allNotificationsRecords.forEach((notification) => {
          let sender: string = null;
          if (notification.type == "friendRequest")
            sender =
              notification.sender.name + " " + notification.sender.surname;
          this.notificationsRecords.push({
            id: notification._id,
            title: notification.title,
            body: notification.body,
            read: notification.read,
            icon: notification.icon,
            sender: sender,
            path: notification.path,
            type: notification.type,
            createdAt: notification.createdAt,
            timeFromNow: this.transform(notification.createdAt),
          });
        });

        this.notificationsRecords.sort(function (a, b) {
          if (a.createdAt > b.createdAt) {
            return -1;
          }
          if (a.createdAt < b.createdAt) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.opendropdownformobile();
    await this.getAllNotifications();
  }

  async getAllNotifications() {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
    };
    await (
      await this._authService.getNavbarNotifications(notificationVm)
    ).subscribe();
  }

  async markAllNotificationAsRead() {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
    };

    await (
      await this._authService.markAllNotificationsAsRead(notificationVm)
    ).subscribe(
      (newData) => {
        this.emitterService.eventOnChange.emit("updateNotification");
      },
      (err) => {
        console.log("error");
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
    this._serviceNotificationSubscription.unsubscribe();
    schedule.cancelJob();
  }

  transform(value: string) {
    let now = moment(value).fromNow();
    return now;
  }

  ngAfterViewInit() {
    // this.minimizeSidebar();
  }

  backButton() {
    this.location.back();
  }

  async logout() {
    await this._authService.logout();
    this.router.navigate(["/login"]);
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  // isMobileMenu() {
  //     if (window.outerWidth < 991) {
  //         return false;
  //     }
  //     return true;
  // }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 786) {
      mainPanel.style.position = "fixed";
    }
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }

  partialSidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("sidebar")[0]
    );
    const mainPanel1 = <HTMLElement>(
      document.getElementsByClassName("sidebar-wrapper")[0]
    );

    if (window.innerWidth < 489) {
      mainPanel.style.width = "99px";
      mainPanel1.style.width = "99px"; 
    }

    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }

  sidebarClose() {
    var html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 1000);
    }

    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible == false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      var parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        // var children_from_url = titlee.split("/")[2];
        var children_from_url = titlee.split("/")[1];
        for (var current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return "Home";
  }

  getPath() {
    // console.log(this.location);
    return this.location.prepareExternalUrl(this.location.path());
  }

  async markNotificationAsRead(
    path: string,
    notification: string,
    index: number
  ) {
    let notificationVm = {
      id: this.user.id,
      token: this.user.token,
      id_notification: notification,
      read: true,
    };

    if (!this.notificationsRecords[index].read)
      await (
        await this._authService.markNotificationAsRead(notificationVm)
      ).subscribe(
        (newData) => {
          this.emitterService.eventOnChange.emit("updateNotification");
        },
        (err) => {
          console.log("error");
        }
      );

    if (path) {
      console.log("path", path);
    }
  }

  async acceptFriendRequest(notification: string, index: number) {
    const userVm = {
      id: this.user.id,
      token: this.user.token,
      id_notification: notification,
    };

    await (await this._authService.acceptFriendRequest(userVm))
      .pipe(first())
      .subscribe(
        async (data) => {
          this.Toast.fire({
            icon: "success",
            title:
              "You and " +
              this.notificationsRecords[index].sender +
              " are friends now.",
          });
          this.emitterService.eventOnChange.emit("updateNotification");
        },
        async (err) => {
          this.Toast.fire({
            icon: "error",
            title: "An error has occurred. " + err.error.message,
          });
        }
      );
  }

  async declineFriendRequest(notification: string, index: number) {
    const userVm = {
      id: this.user.id,
      token: this.user.token,
      id_notification: notification,
    };
    await (await this._authService.declineFriendRequest(userVm))
      .pipe(first())
      .subscribe(
        async (data) => {
          this.Toast.fire({
            icon: "success",
            title:
              "You declined " +
              this.notificationsRecords[index].sender +
              "'s friend request.",
          });
          this.emitterService.eventOnChange.emit("updateNotification");
        },
        async (err) => {
          this.Toast.fire({
            icon: "error",
            title: "An error has occurred. Please try again.",
          });
        }
      );
  }

  searchBox(event: string) {
    this.searchtext = event.toLowerCase();
    if (this.searchtext.length == 0) this.searchtext = "search";
    this.search.nativeElement.value = "";
    this.router.navigate(["/search-users/" + this.searchtext]);
  }



  stepsContent: string[] = joyrideStepsContent;

  async startTour() {
    this.AppTourService.role = this.user.role;
    this.AppTourService.membership = this.membership;
    this.AppTourService.startTour();
    const dropdownCheck = timer(0, 500);
    this.currentStep = this.AppTourService.currentStep;
    dropdownCheck.subscribe(async () => {
      this.currentStep = this.AppTourService.currentStep;
       if (
        this.currentStep === "step5" ||
        this.currentStep === "step6" ||
        this.currentStep === "step7"
      ) {
        this.dropdown.open();
        
      } 

    });

  }

  opendropdownformobile(){
    if(this.currentStep === "step5"){
    if(window.innerWidth <= 786){
      this.isDropdownOpen = true;
    }
  }
  }


  Opendropdown() {
    this.isDropdownOpen = true;
  }

  Closedropdown() {
    this.isDropdownOpen = false;
  }
}
