import { Injectable, OnInit } from "@angular/core";
import { JoyrideService } from "ngx-joyride";

@Injectable()
export class AppTourService {
  private toggleButton;
  private sidebarVisible: boolean;

  tourSteps = [
    "step1@",
    "step2@",
    "step56@",
    "step57@",
    "step58@",
    "step59@",
    "step60@",
    "step66@",
    "step61@",
    "step62@",
    "step63@",
    "step64@",
    "step5@",
    "step6@",
    "step7@",
    "step4@",
    "step8@search-users/search",
    "step9@search-users/search",
    "step10@search-users/search",
    "step11@video-library",
    "step12@fit-camp",
    "step13@all-client-programs",
    "step14@client-training-program-constructor",
    "step15@",
    "step16@",
    "step17@",
    "step18@",
    "step19@",
    "step20@",
    "step21@",
    "step22@",
    "step23@",
    "step24@",
    "step25@",
    "step26@",
    "step27@",
    "step28@",
    "step29@",
    "step30@",
    "step31@",
    "step32@",
    "step67@client-training-program-constructor",
    "step33@settings",
    "step34@settings",
    "step35@settings",
    "step36@tdee",
    "step37@my-client-portal",
    "step38",
    "step39",
    "step40",
    "step41@my-client-portal",
    "step42@chats",
    "step43",
    "step65@home"
  ];

  public currentStep: string = null;
  public role: string = null;
  public membership: string = null;

  constructor(private readonly joyrideService: JoyrideService) {}
    
/*    async startTour() {
    console.log(this.role);
    console.log(this.membership);
    
    if (this.role === "Admin" && this.membership === "Free") {

      this.tourSteps = this.tourSteps.filter(
        (step) =>

          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54") &&
          !step.startsWith("step68")
      );
    } 
    if (this.role === "Admin" && this.membership === "Pro" || this.membership === "Advanced") {
      this.tourSteps = this.tourSteps;
    } 
     if (this.role === "User") {
      // Exclude steps 14 to 32 for User
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step13") &&
          !step.startsWith("step14") &&
          !step.startsWith("step15") &&
          !step.startsWith("step16") &&
          !step.startsWith("step17") &&
          !step.startsWith("step18") &&
          !step.startsWith("step19") &&
          !step.startsWith("step20") &&
          !step.startsWith("step21") &&
          !step.startsWith("step22") &&
          !step.startsWith("step23") &&
          !step.startsWith("step24") &&
          !step.startsWith("step25") &&
          !step.startsWith("step26") &&
          !step.startsWith("step27") &&
          !step.startsWith("step28") &&
          !step.startsWith("step29") &&
          !step.startsWith("step30") &&
          !step.startsWith("step31") &&
          !step.startsWith("step32") &&
          !step.startsWith("step67") &&
          !step.startsWith("step68") &&
          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54")
      );
      
    } 
    if (this.role === "Coach" && this.membership === "Free") {
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step42") &&
          !step.startsWith("step43") &&
          !step.startsWith("step5") &&
          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54") &&
          !step.startsWith("step68")
      );
    } 
    if (this.role === "Coach" && this.membership === "Pro" || this.membership === "Advanced") { 
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step42") &&
          !step.startsWith("step43") &&
          !step.startsWith("step5") &&
          !step.startsWith("step65") &&
          !step.startsWith("step68") 
      );
      this.tourSteps = this.tourSteps.concat(
        "step47@client-diet-program-constructor",
        "step48@client-diet-program-constructor",
        "step49@client-diet-program-constructor",
        "step50@client-diet-program-constructor",
        "step50@client-diet-program-constructor",
        "step51@client-diet-program-constructor",
        "step52@client-diet-program-constructor",
        "step53@client-diet-program-constructor",
        "step54@client-diet-program-constructor",
        "step68@client-diet-program-constructor",
        "step65@home"
      );
      
    }
    
    

    await this.joyrideService
      .startTour({
        themeColor: "#ff00d7",
        steps: this.tourSteps,
        stepDefaultPosition: "right",
        showPrevButton: true,
      })
      .subscribe({
        next: async (step) => {
          this.currentStep = step.name;

          //   if (
          //     this.currentStep == "step5" ||
          //     this.currentStep == "step6" ||
          //     this.currentStep == "step7"
          //   ) {
          //     this.dropdown.open();
          //   }

          if (this.currentStep === "step2" || this.currentStep === "step64") {
            if (window.innerWidth <= 786) {
              this.sidebarOpen();
            }
          }

          if (this.currentStep == "step5") {
            if (window.innerWidth <= 786) {
              this.sidebarClose();
            }
          }
        },
        complete: async () => {
          console.log("Tour finished");
          this.currentStep = null;
        },
      });
  }  */

  async startTour() {
    if (this.role === "Admin"  && this.membership !== "Free") {
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step65")&&
          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54") &&
          !step.startsWith("step68") 
      );
      // Include all steps for Admin with non-free membership
      this.tourSteps = this.tourSteps.concat(
        "step47@client-diet-program-constructor",
        "step48",
        "step49",
        "step50",
        "step51",
        "step52",
        "step53",
        "step54",
        "step68@client-diet-program-constructor",
        "step65@home"
      );
    } 
    else if (this.role === "Admin" && this.membership === "Free") {
      // Exclude steps 42 to 43 and step5 for Coach
      this.tourSteps = this.tourSteps.filter(
        (step) =>
        !step.startsWith("step47") &&
        !step.startsWith("step48") &&
        !step.startsWith("step49") &&
        !step.startsWith("step50") &&
        !step.startsWith("step51") &&
        !step.startsWith("step52") &&
        !step.startsWith("step53") &&
        !step.startsWith("step54") &&
        !step.startsWith("step68") &&
        !step.startsWith("step65")
      );
      this.tourSteps = this.tourSteps.concat(
        "step65@chome",
        
      );
    } 
    else if (this.role === "User") {
      // Exclude steps 14 to 32 for User
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step13") &&
          !step.startsWith("step14") &&
          !step.startsWith("step15") &&
          !step.startsWith("step16") &&
          !step.startsWith("step17") &&
          !step.startsWith("step18") &&
          !step.startsWith("step19") &&
          !step.startsWith("step20") &&
          !step.startsWith("step21") &&
          !step.startsWith("step22") &&
          !step.startsWith("step23") &&
          !step.startsWith("step24") &&
          !step.startsWith("step25") &&
          !step.startsWith("step26") &&
          !step.startsWith("step27") &&
          !step.startsWith("step28") &&
          !step.startsWith("step29") &&
          !step.startsWith("step30") &&
          !step.startsWith("step31") &&
          !step.startsWith("step32") &&
          !step.startsWith("step33") &&
          !step.startsWith("step34") &&
          !step.startsWith("step35") &&
          !step.startsWith("step67") &&
          !step.startsWith("step65")
      );
      this.tourSteps = this.tourSteps.concat(
        "step65@chome",
        
      );
    } 
    else if (this.role === "Coach" && this.membership !== "Free") {
      // Exclude steps 42 to 43 and step5 for Coach
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step42") &&
          !step.startsWith("step43") &&
          !step.startsWith("step5") &&
          !step.startsWith("step65") &&
          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54") &&
          !step.startsWith("step68") &&
          !step.startsWith("step65")
      );
      this.tourSteps = this.tourSteps.concat(
        "step47@client-diet-program-constructor",
        "step48",
        "step49",
        "step50",
        "step51",
        "step52",
        "step53",
        "step54",
        "step68@client-diet-program-constructor",
        "step65@home"
        
      );
    }
    else if (this.role === "Coach" && this.membership === "Free") {
      // Exclude steps 42 to 43 and step5 for Coach
      this.tourSteps = this.tourSteps.filter(
        (step) =>
          !step.startsWith("step42") &&
          !step.startsWith("step43") &&
          !step.startsWith("step5")  &&
          !step.startsWith("step47") &&
          !step.startsWith("step48") &&
          !step.startsWith("step49") &&
          !step.startsWith("step50") &&
          !step.startsWith("step51") &&
          !step.startsWith("step52") &&
          !step.startsWith("step53") &&
          !step.startsWith("step54") &&
          !step.startsWith("step68") &&
          !step.startsWith("step65")
      );
      this.tourSteps = this.tourSteps.concat(
        "step65@chome",
        
      );
    } 

  
    await this.joyrideService
      .startTour({
        themeColor: "#ff00d7",
        steps: this.tourSteps,
        stepDefaultPosition: "right",
        showPrevButton: true,
      })
      .subscribe({
        next: async (step) => {
          this.currentStep = step.name;
  
          // Additional logic for specific steps if needed
  
          if (this.currentStep === "step2" || this.currentStep === "step64") {
            if (window.innerWidth <= 786) {
              this.sidebarOpen();
            }
          }
  
          if (this.currentStep === "step5") {
            if (window.innerWidth <= 786) {
              this.sidebarClose();
            }
          }
        },
        complete: async () => {
          console.log("Tour finished");
          this.currentStep = null;
        },
      });
  } 
  
  
  

  
  sidebarClose() {
    var html = document.getElementsByTagName("html")[0];
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
  }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const mainPanel1 = <HTMLElement>(
      document.getElementsByClassName("sidebar-wrapper")[0]
    );

    if (window.innerWidth < 991) {
      mainPanel1.style.position = "fixed";
    }
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }
}

export const joyrideStepsContent = [
  'Welcome to Cardio Flourish! This is a quick tour of the website. Click "Next" to continue.',
  "This is the navigation bar. You can use it to navigate the website.",
  "This is the search bar. You can use it to search for other users.",
  "This is the notification bell. You will receive notifications here.",
  "This is the profile button. You can use it to access your profile.",
  "This is your settings button. You can use it to access your settings.",
  "This is the logout button. You can use it to logout.",
  "This is the search bar. You can use it to search for other users.",
  "Use filters to find relevant results and set distance if required.",
  "Select fitness interest to find those with common fitness interests and Select fitness speciality to find a coach who specialises in your area of interest",
  "Find an exercise by typing it into the search bar",
  "Select a Fit Camp tutorial you would like to explore.",
  "Here you will find all your client programs and the people they are assigned to.",
  "This page is for creating new Training programs",
  "Select a clients you want to assign your program to",
  "Create a name for your program", //
  "Add a new training day", //
  "Hit the plus icon to add new training to the same session", //
  "Delete training or remove training day", //
  "Delete training or remove training day", //
  "Set number of rounds", //
  "Set target Heart Rate range", ////
  "Name Stretch", //
  "Name Muscels worked", //
  "Add length of time for stretch", //
  "Select demonstration video", //
  "Name exercise", //
  "Select weight to be used",
  "Add target reps", //
  "Add target sets", //
  "Add Tempo", //
  "Select tutorial video", //
  "Add fitness specialties to be more easily discoverd by potential clients", ///
  "Add your Qualifictions", ///
  "Edit your Bio",
  "TDEE Stands for Total Daily Energy Expenditure and estimates how many calories you need consume in order to maintain your weight",
  "Add your height",
  "Add your body weight",
  "Upload progress photos",
  "Share your client portal with others",
  "Your client portal is where your coach can view your progress",
  "This is the where all the chats populate",
  "To start a chat search for a connection you wish to chat with and send them a message",
  "Add client you wish to assign meal plan to",
  "Give a name to your Meal plan", ///
  "Add new day", //
  "This is diet program constructor", //
  "Add Cheat day", //
  "Add new meal to the same day", //
  "Macronutrient daily values are displayed here", //
  "Add new food item", //
  "Add meal type", //
  "Search for food item", //
  "Add food item in grams", //
  "You've completed the app tour successfully. If you have any more questions or need assistance, feel free to reach out to our dedicated support team. We're here to help make your experience even better.",
  "Save or publish your program", //55
  "Save or publish your program" 
];
