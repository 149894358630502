import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyPolicyComponent } from 'app/pages/app-area/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from 'app/pages/app-area/terms-and-conditions/terms-and-conditions.component';

declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html'
})


export class AuthLayoutComponent {
    today: Date = new Date();
    private toggleButton;
    private sidebarVisible: boolean;

    private nativeElement: Node;

    constructor(private element: ElementRef, private modalService: NgbModal,
        public router: Router) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    async ngOnInit() {

        var navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        // var body = document.getElementsByTagName('body')[0];
        // body.classList.add('auth-layout-page');

        setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)

    }

    // ngOnDestroy() {
    //     var body = document.getElementsByTagName('body')[0];
    //     body.classList.remove('auth-layout-page');
    // }

    sidebarToggle() {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        var sidebar = document.getElementsByClassName('navbar-collapse')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function () {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }

    closeNavegation(event) {
        console.log('event', event);
    }

    async seeTerms() {
        const modalRef = this.modalService.open(TermsAndConditionsComponent, { size: 'xl', centered: true, scrollable: true });

    }

    async seePrivacy() {
        const modalRef = this.modalService.open(PrivacyPolicyComponent, { size: 'xl', centered: true, scrollable: true });
    }

}
