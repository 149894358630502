import { Location, LocationStrategy } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivacyPolicyComponent } from "app/pages/app-area/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "app/pages/app-area/terms-and-conditions/terms-and-conditions.component";
import { AuthenticationService } from "providers/authentication.service";
import { Subscription } from "rxjs";
import { joyrideStepsContent } from "providers/app-tour/app-tour";

@Component({
  moduleId: module.id,
  selector: "footer-cmp",
  templateUrl: "footer.component.html",
})
export class FooterComponent implements OnInit {
  previousUrl: string;

  counter = 0;

  private subscriptionUser: Subscription;
  isItMobile: boolean = false;

  isUserCoach: boolean = false;

  constructor(
    private location: Location,
    private modalService: NgbModal,
    private _authService: AuthenticationService,
    public router: Router,
    public locationStrategy: LocationStrategy
  ) {}

  today: Date = new Date();
  stepsContent: string[] = joyrideStepsContent;
  ngOnInit() {
    this.subscriptionUser = this._authService.authUserChange.subscribe(
      async (value) => {
        let user: any[] = value ? JSON.parse(value) : {};
        this.isUserCoach = user["role"] == "User" ? false : true;

        this.isItMobile =
          Capacitor.getPlatform() == "ios" ||
          Capacitor.getPlatform() == "android"
            ? true
            : false;
      }
    );
  }

  async backClicked() {
    if (this.location.path() == "/home") this.counter = 0;
    else this.counter++;

    // this.locationStrategy.onPopState();

    // window.history.back();
    await this.location.back();

    // this.locationStrategy.back();
  }

  async seeTerms() {
    const modalRef = this.modalService.open(TermsAndConditionsComponent, {
      size: "xl",
      centered: true,
      scrollable: true,
    });
  }

  async seePrivacy() {
     const modalRef = this.modalService.open(PrivacyPolicyComponent, {
      size: "xl",
      centered: true,
      scrollable: true,
    }); 
    //this.router.navigate(["/privacy"]);
  }

  ngOnDestroy() {
    this.subscriptionUser.unsubscribe();
  }
}
