import { Capacitor } from "@capacitor/core";
import { StoreProvider } from "./store/store";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "./config/config.service";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export const USER_NAME: string = "currentUser";
export const CHAT_ID: string = "chatid";
export const PROGRAM_ID: string = "programid";
export const FITCAMP_ID: string = "fitcamp_id";
export const QUESTIONNAIRE_ID: string = "questionnaire_id";
export const SURVEY_ID: string = "survey_id";
export const FITCAMP_RESPONSE_ID: string = "fitcamp_response_id";
export const QUESTIONNAIRE_RESPONSE_ID: string = "questionnaire_response_id";
export const BLOG_ID: string = "blog_id";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  // Variable for determining if a user is logged in or not
  private authStatus$ = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<string>("");
  private notifications = new BehaviorSubject<string>("");

  userID: string;
  tokenID: string;

  constructor(
    private http: HttpClient,
    private storage: StoreProvider,
    private router: Router,
    private modalService: NgbModal,
    private readonly _configService: ConfigService
  ) {
    this.authUserChange.subscribe((value) => {
      let user: any[] = value ? JSON.parse(value) : {};
      this.userID = user["_id"];
      this.tokenID = user["token"];
    });
  }

  async checkAuthorization() {
    const tokenVm = {
      id: this.userID,
      token: this.tokenID,
    };

    this.setIsAuthorized(false);
    await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .subscribe(
        async (user) => {
          if (user && user.token) {
            // this.setToken(user.token);
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;

            this.setIsAuthorized(true);
          }
          //return user;

          // if (user && user.token) {
          //   // if (await this.getIsItMasterLogin()) {
          //   //   // user.user.role = 'User';
          //   //   // user.user.privilege.companyAdministrator = false;
          //   // }
          //   this.setUserLogin(JSON.stringify(user.user));
          //   this.setIsAuthorized(true);
          // }
        },
        async (err) => {
          console.log("err", err);
          // await this.logout();
          // this.router.navigate(['/login']);
        }
      );
  }

  // An observable that will change when our user has logged in
  get authStatusChange() {
    return this.authStatus$.asObservable();
  }

  get authUserChange() {
    return this.user.asObservable();
  }

  get authUserNotifications() {
    return this.notifications.asObservable();
  }

  // Set the is authorised
  setIsAuthorized(value: boolean) {
    this.authStatus$.next(value);
  }

  // Get the value of the user authorisation
  isAuthorized() {
    return this.authStatus$.value;
  }

  // Set the user
  async setItem(key: string, item: string): Promise<void> {
    await this.storage.setItem(key, item);
  }

  // Get the user
  async getItem(key: string): Promise<any> {
    return await this.storage.getItem(key);
  }

  // Clear the user
  async clearItem(key: string) {
    return await this.storage.remove(key);
  }

  async setCurrentChat(chatId: string): Promise<void> {
    await this.storage.setItem(CHAT_ID, chatId);
  }

  async getCurrentChat(): Promise<string> {
    return await this.storage.getItem(CHAT_ID);
  }

  async getProgram(): Promise<string> {
    return await this.storage.getItem(PROGRAM_ID);
  }

  async setProgram(programId: string): Promise<void> {
    await this.storage.setItem(PROGRAM_ID, programId);
  }

  async setBlog(blogId: string): Promise<void> {
    await this.storage.setItem(BLOG_ID, blogId);
  }

  async getBlog(): Promise<string> {
    return await this.storage.getItem(BLOG_ID);
  }

  async setFitCamp(fitCamp: string): Promise<void> {
    await this.storage.setItem(FITCAMP_ID, fitCamp);
  }

  async setQuestionnaire(questionnaire: string): Promise<void> {
    await this.storage.setItem(QUESTIONNAIRE_ID, questionnaire);
  }

  async setSurvey(survey: string): Promise<void> {
    await this.storage.setItem(SURVEY_ID, survey);
  }

  async getAllSurveysGlobal(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "survey/getGlobalAll",
      surveyVm
    );
  }

  async getAllCategoriesByType(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "category/getAllByType",
      categoryVm
    );
  }

  async createSurvey(surveyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "survey/createSurvey",
      surveyVm
    );
  }

  async editCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "category/editcategory", categoryVm)
      .pipe(map((data) => {}));
  }

  async getAllCategories(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "category/getAll",
      categoryVm
    );
  }

  async getAllBlogs(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "blog/getAll",
      categoryVm
    );
  }

  async getAllCardioFlourishBlogsPost(categoryVm: any) {
    return await this.http.post<any>(
      this._configService.url + "blog/getAllCardioFlourishBlogsPost",
      categoryVm
    );
  }

  async deleteAdminCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "category/deleteAdminCategory",
      categoryVm
    );
  }

  async createCategory(categoryVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "category/create", categoryVm)
      .pipe(map((data) => {}));
  }

  async getFitCamp(): Promise<string> {
    return await this.storage.getItem(FITCAMP_ID);
  }

  async getQuestionnaire(): Promise<string> {
    return await this.storage.getItem(QUESTIONNAIRE_ID);
  }

  async setFitCampResponse(response: string): Promise<void> {
    await this.storage.setItem(FITCAMP_RESPONSE_ID, response);
  }

  async setQuestionnaireResponse(response: string): Promise<void> {
    await this.storage.setItem(QUESTIONNAIRE_RESPONSE_ID, response);
  }

  async getFitCampResponse(): Promise<string> {
    return await this.storage.getItem(FITCAMP_RESPONSE_ID);
  }

  async getQuestionnaireResponse(): Promise<string> {
    return await this.storage.getItem(QUESTIONNAIRE_RESPONSE_ID);
  }

  setUserLogin(user: any): void {
    this.user.next(user);
  }

  setNotification(notifications: any): void {
    this.notifications.next(notifications);
  }

  // Get the user
  getUser(): any {
    return this.user.value;
  }

  // Clear the user
  async clearUser() {
    return await this.storage.remove(USER_NAME);
  }

  getAPI() {
    return this.http.get<any>(this._configService.url);
  }

  async login(loginVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/login", loginVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            user.user.isAuthorized = true;
            this.router.navigate(["/home"]);
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async token(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            user.user.isAuthorized = true;
            this.setUserLogin(JSON.stringify(user.user));
            this.router.navigate(["/home"]);
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async EditUser(userVm: any) {
    return await this.http
      .post<any>(this._configService.url + "user/editUser/", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async EditUserBio(userVm: any) {
    return await this.http
      .post<any>(this._configService.url + "user/editUserBio/", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async deleteProfilePhoto(userVm: any) {
    return await this.http
      .post<any>(
        this._configService.url + "multimedia/deleteProfilePhoto",
        userVm
      )
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async deleteClientPortalPhoto(userVm: any) {
    return await this.http
      .post<any>(
        this._configService.url + "client-portal/deleteClientPortalPhoto",
        userVm
      )
      .pipe(map((data) => {}));
  }

  async tokenDashboard(tokenVm) {
    this.setIsAuthorized(false);
    return await this.http
      .post<any>(this._configService.url + "user/token", tokenVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
            this.setIsAuthorized(true);
          }
          return user;
        })
      );
  }

  async register(register: any) {
    return await this.http
      .post<any>(this._configService.url + "user/register", register)
      .pipe(map((data) => {}));
  }

  async createCalendarEvent(calendarVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "calendar/createEvent", calendarVm)
      .pipe(map((data) => {}));
  }

  async clientPortalNewWeightEntry(weightVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "client-portal/clientPortalNewWeightEntry",
        weightVm
      )
      .pipe(map((data) => {}));
  }

  async clientPortalNewHeightEntry(heightVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "client-portal/clientPortalNewHeightEntry",
        heightVm
      )
      .pipe(map((data) => {}));
  }

  async createPost(postVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "post/create", postVm)
      .pipe(map((data) => {}));
  }

  async createFaq(faq: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "faq/create", faq)
      .pipe(map((data) => {}));
  }

  async createProgram(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/createProgram",
      programVm
    );
  }

  async saveProgram(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/saveProgram",
      programVm
    );
  }

  async updateProgramPublication(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/updateProgramPublication",
      programVm
    );
  }

  async sendEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "newsletter/sendEmail", sendEmailVm)
      .pipe(map((data) => {}));
  }

  async deleteEmail(sendEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/deleteContactUs",
      sendEmailVm
    );
  }

  async uploadMultimedia(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/uploadMultimedia",
      formData
    );
  }

  // async getAllYoutubeVideos(multimediaVm: any) {
  //   await this.checkAuthorization();
  //   return await this.http.post<any>(
  //     this._configService.url + "multimedia/getAllYoutubeVideos",
  //     multimediaVm
  //   );
  // }

  async getAllLibrarysVideos(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/getAllLibrarysVideos",
      multimediaVm
    );
  }

  async getAllNutritionInformation(nutritionVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/getAllNutritionInformation",
      nutritionVm
    );
  }

  async uploadProfilePicture(formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "multimedia/uploadProfilePicture",
        formData
      )
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async uploadPhotos(formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "multimedia/uploadPhotos", formData)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async uploadClientPortalPhotos(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/uploadClientPortalPhotos",
      formData
    );
  }

  async videoUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/videoUpload",
      formData
    );
  }

  async certificationUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "certification/uploadCertificate",
      formData
    );
  }

  async nutritionFileUpload(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/uploadNutritionFile",
      formData
    );
  }

  async editUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/role/", userVm)
      .pipe(map((data) => {}));
  }

  async editUserPrivilege(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/privilege/", userVm)
      .pipe(map((data) => {}));
  }

  async addImage(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/addImage",
      formData
    );
  }

  async SystemProfilePicture(id: string, userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .put<any>(this._configService.url + "user/SystemProfilePicture/" + id, {
        userVm,
      })
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async EditPassword(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "user/password", userVm)
      .pipe(map((data) => {}));
  }

  async EditProfilePicture(id: string, formData: FormData) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "multimedia/profilepicture/" + id,
        formData
      )
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async getAllContactUsMessage(contactUsVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/contactUs",
      contactUsVm
    );
  }

  async getAllNewsletter(newsletterVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/newsletter",
      newsletterVm
    );
  }

  async getAllFaq() {
    await this.checkAuthorization();
    return await this.http.get(this._configService.url + "faq");
  }

  async getAdminAllUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAdminAllUsers",
      userVm
    );
  }

  async getAllUsersExercises(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "exercise/getAllUsersExercises",
      userVm
    );
  }

  async getClientPortal(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "client-portal/getClientPortal",
      userVm
    );
  }

  async getClientPortalHealthRecords(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "client-portal/getClientPortalHealthRecords",
      userVm
    );
  }

  async getFriendsClientPortalHealthRecords(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "client-portal/getFriendsClientPortalHealthRecords",
      userVm
    );
  }

  async getFriendClientPortal(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "client-portal/getFriendClientPortal",
      userVm
    );
  }

  async createClientStripeAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/createClientStripeAccount",
      userVm
    );
  }

  async getClientStripeAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getClientStripeAccount",
      userVm
    );
  }

  async getClientStripePaymentLink(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getClientStripePaymentLink",
      userVm
    );
  }

  async getStripeAccountLinkAndMembershipLink(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/getStripeAccountLinkAndMembershipLink",
      userVm
    );
  }

  async stripeCancelSubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/stripeCancelSubscription",
      userVm
    );
  }

  async stripeUpdateUserSubscriptionInformation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "payment/stripeUpdateUserSubscriptionInformation",
      userVm
    );
  }

  async stripeReativateSubscription(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "payment/stripeReativateSubscription",
      userVm
    );
  }
  async getAllUserFriends(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserFriends",
      userVm
    );
  }

  async getAllUserCoaches(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserCoaches",
      userVm
    );
  }

  async getAllCountries(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/getAllCountries",
      userVm
    );
  }

  async getAllCountryCities(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/getAllCitiesFromACountry",
      userVm
    );
  }

  async searchRequestAll(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchRequestAll",
      userVm
    );
  }

  async searchRequestCity(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchRequestCity",
      userVm
    );
  }

  async searchNutrition(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "nutrition/searchNutrition",
      userVm
    );
  }

  // async createFitCamp(fitCampVm: any) {
  //   await this.checkAuthorization();
  //   return await this.http.post<any>(
  //     this._configService.url + "fitCamp/createFitCamp",
  //     fitCampVm
  //   );
  // }

  async sendClientPortalCheckIn(clientPortalVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "questionnaire/check-in",
      clientPortalVm
    );
  }

  async sendHealthData(healthVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "health/sendHealthData",
      healthVm
    );
  }

  async createFitCamp(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/createFitCamp",
      formData
    );
  }

  async newBlogPost(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "blog/newBlogPost",
      formData
    );
  }

  async editBlog(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "blog/editBlog",
      formData
    );
  }

  async createQuestionnaire(formData: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/createQuestionnaire",
      formData
    );
  }

  async getFitCampDetails(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getFitCampDetails",
      fitCampVm
    );
  }

  async getBlogPostDetails(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "blog/getBlogPostDetails",
      fitCampVm
    );
  }

  async getQuestionnaireDetails(QuestionnaireVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/getQuestionnaireDetails",
      QuestionnaireVm
    );
  }

  async getFitCampToRespond(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getFitCampToRespond",
      fitCampVm
    );
  }

  async getQuestionnaireToRespond(QuestionnaireVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/getQuestionnaireToRespond",
      QuestionnaireVm
    );
  }

  async getCoachsFitCampToRespond(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getCoachsFitCampToRespond",
      fitCampVm
    );
  }

  async getUserResponseFitCampDetails(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getUserResponseFitCampDetails",
      fitCampVm
    );
  }

  async getFitCampResponseDetails(respondVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "response/getFitCampResponseDetails",
      respondVm
    );
  }

  async getQuestionnaireResponseDetails(respondVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire-response/getQuestionnaireResponseDetails",
      respondVm
    );
  }

  async respondFitCamp(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "response/respondFitCamp",
      responseVm
    );
  }

  async respondQuestionnaire(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire-response/respondQuestionnaire",
      responseVm
    );
  }

  async publishFitCamp(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/publishFitCamp",
      fitCampVm
    );
  }
  async publishQuestionnaire(QuestionnaireVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/publishQuestionnaire",
      QuestionnaireVm
    );
  }

  // async editFitCamp(fitCampVm: any) {
  //   await this.checkAuthorization();
  //   return await this.http.post<any>(
  //     this._configService.url + "fitCamp/editFitCamp",
  //     fitCampVm
  //   );
  // }

  async editFitCamp(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/editFitCamp",
      formData
    );
  }

  async editQuestionnaire(formData: FormData) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/editQuestionnaire",
      formData
    );
  }

  async getAllFitCampResponses(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "response/getAllFitCampResponses",
      responseVm
    );
  }

  async getAllQuestionnaireResponses(responseVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire-response/getAllQuestionnaireResponses",
      responseVm
    );
  }

  async getAllUserClients(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllUserClients",
      userVm
    );
  }

  async getProgramInformation(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getProgram",
      userVm
    );
  }

  async getLibraryProgram(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getLibraryProgram",
      userVm
    );
  }

  async downloadProgramFile(downloadVm: any) {
    await this.checkAuthorization();
    return await this.http.post(
      this._configService.url + "reports/generateProgramReport",
      downloadVm,
      { observe: "response", responseType: "blob" }
    );
  }

  async getAllMyFriends(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllMyFriends",
      userVm
    );
  }

  async getAllAdminCoachUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getAllAdminCoachUsers",
      userVm
    );
  }

  async getAllFitCamps(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getAll",
      userVm
    );
  }

  async getAllCoachQuestionnaires(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire/getAllCoachsCoachQuestionnaires",
      userVm
    );
  }

  async getAllClientsQuestionnaires(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire/getAllClientsQuestionnaires",
      userVm
    );
  }

  async getAllCoachsFitCamps(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getAllCoachsFitCamps",
      userVm
    );
  }

  async getAllPublishedFitCamps(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getAllPublishedFitCamps",
      userVm
    );
  }

  async getAllPublishedFitCampsOfACategory(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getAllPublishedFitCampsOfACategory",
      userVm
    );
  }

  async getAllCoachsPublishedFitCampsOfACategory(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "fitCamp/getAllCoachsPublishedFitCampsOfACategory",
      userVm
    );
  }

  async getAllPublishedFitCampsCategories(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/getAllPublishedFitCampsCategories",
      userVm
    );
  }

  async getAllPublishedQuestionnairesCategories(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "coach-questionnaire/getAllPublishedQuestionnairesCategories",
      userVm
    );
  }

  async getAllCoachsPublishedFitCampsCategories(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "fitCamp/getAllCoachsPublishedFitCampsCategories",
      userVm
    );
  }

  async getAllCoachsPublishedFitCampsCategoriesToUserResponse(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "fitCamp/getAllCoachsPublishedFitCampsCategoriesToUserResponse",
      userVm
    );
  }

  async reorganiseFitCamps(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/reorganiseFitCamps",
      userVm
    );
  }

  async getEvent(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/getEvent",
      challengeVm
    );
  }

  async getAllEvents(challengeVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/getAllEvents",
      challengeVm
    );
  }

  async getAllMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/getAllMessages",
      userVm
    );
  }

  async getAllFeedActivities(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/getAllActivityFeeds",
      userVm
    );
  }

  async getAllConversationMessages(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/conversation",
      userVm
    );
  }

  async sendMessageResponse(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/createMessageResponse",
      userVm
    );
  }

  async searchUsers(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/searchUsers",
      userVm
    );
  }

  async getAllCoachPrograms(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getAllCoachPrograms",
      userVm
    );
  }

  async getAllCoachProgramLibrary(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getAllCoachProgramLibrary",
      userVm
    );
  }

  async getAllCoachCertifications(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "certification/getAllCoachCertificates",
      userVm
    );
  }

  async getAllCoachProfileCertificates(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "certification/getAllCoachProfileCertificates",
      userVm
    );
  }

  async getAllClientsPrograms(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/getAllClientsPrograms",
      userVm
    );
  }

  async getAllTestimonies(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "testimony/getAllTestimonies",
      userVm
    );
  }

  async approveTestimony(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "testimony/approveTestimony",
      userVm
    );
  }

  async createConversation(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/createConversation",
      messageVm
    );
  }

  async addUserToClientPortalFriendsList(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/addUserToClientPortalFriendsList",
      messageVm
    );
  }

  async removeUserFromClientPortalFriendsList(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/removeUserFromClientPortalFriendsList",
      messageVm
    );
  }

  async createTestimony(testimonyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "testimony/createNewTestimony",
      testimonyVm
    );
  }

  async editVideoInformation(videoVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/editVideoInformation",
      videoVm
    );
  }

  async createFeed(feedVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/createFeed",
      feedVm
    );
  }

  async editFeed(feedVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/editFeed",
      feedVm
    );
  }

  async deleteFeed(feedVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/deleteActivityFeed",
      feedVm
    );
  }

  async deleteProgram(programVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "program/deleteProgram",
      programVm
    );
  }

  async deleteFitCamp(fitCampVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "fitCamp/deleteFitCamp",
      fitCampVm
    );
  }

  async deleteBlogsPost(blogVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "blog/deleteBlogPost",
      blogVm
    );
  }

  async deleteQuestionnaire(QuestionnaireVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "coach-questionnaire/deleteQuestionnaire",
      QuestionnaireVm
    );
  }

  async deleteCoachCertification(certificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "certification/deleteCertificate",
      certificationVm
    );
  }

  async updateFeedsLikeList(feedVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "activity-feed/updateFeedsLikeList",
      feedVm
    );
  }

  async sendChatMessage(messageVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "messages/create", messageVm)
      .pipe(map((data) => {}));
  }

  async sendNotification(notification: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "notification/notify", notification)
      .pipe(map((data) => {}));
  }

  async sendAdminEmail(emailVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "notification/sendAdminEmail",
        emailVm
      )
      .pipe(map((data) => {}));
  }

  async getAllNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllNotifications",
      notificationVm
    );
  }

  async getAllSystemNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllSystemNotifications",
      notificationVm
    );
  }

  async getAllSystemEmails(emailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/getAllSystemEmails",
      emailVm
    );
  }

  async getNavbarNotifications(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(
        this._configService.url + "notification/getNavbarNotifications",
        notificationVm
      )
      .pipe(
        map(
          async (notifications) => {
            this.setNotification(JSON.stringify(notifications));
          },
          (err) => {
            this.setNotification("");
          }
        )
      );
  }

  async markAllNotificationsAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/markAllNotificationsAsRead",
      notificationVm
    );
  }

  async issueNewVerificationEmail(verificationEmailVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/issueNewVerificationEmail",
      verificationEmailVm
    );
  }

  async sendUserCompanyEmailInvitation(invitationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/sendUserCompanyEmailInvitation",
      invitationVm
    );
  }

  async emailVerification(verificationEmailVm: any) {
    return await this.http.post<any>(
      this._configService.url + "verificationGeneration/emailVerification",
      verificationEmailVm
    );
  }

  async markNotificationAsRead(notificationVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "notification/markNotificationAsRead",
      notificationVm
    );
  }

  async forgotPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/newPasswordRequestCreation",
      forgotPasswordVm
    );
  }

  async resetPassword(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url + "verificationGeneration/resetPassword",
      forgotPasswordVm
    );
  }

  async checkPasswordExpiration(forgotPasswordVm: any) {
    return await this.http.post<any>(
      this._configService.url +
        "verificationGeneration/checkPasswordExpiration",
      forgotPasswordVm
    );
  }

  async logoutNotification(user: string) {
    return await this.http
      .put<any>(this._configService.url + "user/logout/" + user, {})
      .pipe(map((data) => {}));
  }

  async download(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post(
      this._configService.url + "multimedia/download/",
      multimediaVm,
      { responseType: "blob" }
    );
  }

  async deleteFaq(faqVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "faq/deleteFaq",
      faqVm
    );
  }

  async deleteContactUsMessage(contactUsMessageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "newsletter/deleteContactUs",
      contactUsMessageVm
    );
  }

  async deleteUser(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteUser",
      userVm
    );
  }

  async deleteUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteUserAccount",
      userVm
    );
  }

  async resetProfilePicture(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "user/resetProfilePicture", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async deleteAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/deleteAdminUserAccount",
      userVm
    );
  }

  async deleteMultimedia(multimediaVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "multimedia/deleteMultimedia",
      multimediaVm
    );
  }

  async deleteAdminEvent(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "calendar/deleteAdminEvent",
      companyVm
    );
  }

  async enableAdminUserAccount(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/enableAdminUserAccount",
      userVm
    );
  }

  async getUserProfile(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/getUserProfile",
      userVm
    );
  }

  async activateAdminCompany(companyVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "company/activateAdminCompany",
      companyVm
    );
  }

  async changeAdminUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeAdminUserRole",
      userVm
    );
  }

  async changeUserMembership(userVm: any) {
    await this.checkAuthorization();
    return await this.http
      .post<any>(this._configService.url + "user/changeUserMembership", userVm)
      .pipe(
        map(async (user) => {
          if (user && user.token) {
            await this.storage.setItem("token", user.token);
            await this.storage.setItem("id", user.user._id);
            delete user.token;
            await this.storage.setItem(
              "currentUser",
              JSON.stringify(user.user)
            );
            this.setUserLogin(JSON.stringify(user.user));
            user.user.isAuthorized = true;
          }
          return user;
        })
      );
  }

  async sentUserAFriendRequest(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/sentUserAFriendRequest",
      userVm
    );
  }

  async getDistance(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "search/getDistance",
      userVm
    );
  }

  async acceptFriendRequest(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/acceptFriendRequest",
      userVm
    );
  }

  async declineFriendRequest(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/declineFriendRequest",
      userVm
    );
  }

  async cancelFriendRequest(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/cancelFriendRequest",
      userVm
    );
  }

  async removeUserAsFriend(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/removeUserAsFriend",
      userVm
    );
  }

  async checkIfThereIsAChat(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "message/checkIfThereIsAChat",
      userVm
    );
  }

  async changeUserRole(userVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "user/changeUserRole",
      userVm
    );
  }

  async deleteConversation(messageVm: any) {
    await this.checkAuthorization();
    return await this.http.post<any>(
      this._configService.url + "messages/deleteconversation",
      messageVm
    );
  }

  async logout() {
    const firebaseToken = await this.storage.getItem("firebaseToken");

    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      const user = JSON.parse(await this.storage.getItem("currentUser"));
      const userVm = {
        id: user._id,
        token: user.token,
        firebaseToken: firebaseToken,
      };
      await this.http.post<any>(
        this._configService.url + "user/unregisterForPushNotifications",
        userVm
      );
    }
    this.modalService.dismissAll();
    await this.setIsAuthorized(false);
    await this.clearUser();
    await this.storage.clear();
    await this.storage.setItem("firebaseToken", firebaseToken);
    await this.setUserLogin(JSON.stringify(""));
  }
}
