declare interface DROPDOWNTYPE {
  value: string;
  type: string;
}

declare interface FOODSUBGROUPCODE {
  foodGroup: string;
  subGroup: string;
  code: string;
}

export declare interface DROPDOWNTYPETAGDISPLAY {
  display: string;
  value: string;
}

export declare interface DROPDOWNTYPENUMBERDISPLAY {
  display: string;
  value: number;
}

declare interface DROPDOWNCATEGORY {
  value: string;
  category: string;
}

export const answerType: DROPDOWNTYPE[] = [
  // { value: "shortAnswer", type: "Short Answer" },
  // { value: "paragraph", type: "Paragraph" },
  { value: "multipleChoice", type: "Multiple Choice" },
  { value: "dropdown", type: "Dropdown" },
  // { value: "linearScale", type: "Linear Scale" },
  // { value: "multipleChoiceGrid", type: "Multiple-choice Grid" },
];

export const trainingType: DROPDOWNTYPE[] = [
  { type: "Aerobic", value: "AEROBIC_TRAINING" },
  { type: "Anaerobic", value: "ANAEROBIC_TRAINING" },
  { type: "Resistance Training", value: "RESISTANCE_TRAINING" },
  { type: "Stretching", value: "STRETCHING_TRAINING" },
];

export const mealType: DROPDOWNTYPE[] = [
  { type: "Breakfast", value: "BREAKFAST" },
  { type: "Lunch", value: "LUNCH" },
  { type: "Dinner", value: "DINNER" },
  { type: "Snack", value: "SNACK" },
];

export enum programType {
  Training = "TRAINING",
  Diet = "DIET",
}

export const videoLibraryCategory: DROPDOWNCATEGORY[] = [
  { category: "Yoga", value: "YOGA" },
  { category: "Cardio", value: "CARDIO" },
  { category: "Bodybuilding and Strength", value: "BODYBUILDING_AND_STRENGTH" },
  { category: "Stretching", value: "STRETCHING" },
];

export const fitnessEnthusiastsInterests: DROPDOWNTYPETAGDISPLAY[] = [
  { display: "General Fitness", value: "GENERAL_FITNESS" },
  { display: "Zumba", value: "ZUMBA" },
  { display: "Aerobics", value: "AEROBICS" },
  { display: "CrossFit", value: "CROSSFIT" },
  { display: "TRX Training", value: "TRX_TRAINING" },
  { display: "Power Lifting", value: "POWER_LIFTING" },
  { display: "Bodybuilding", value: "BODYBUILDING" },
  { display: "Men's Physique", value: "MEN'S_PHYSIQUE" },
  { display: "Women's Physsique", value: "WOMEN'S_PHYSSIQUE" },
  { display: "Bikini Competitor", value: "BIKINI_COMPETITOR" },
  { display: "Calisthenics", value: "CALISTHENICS" },
  { display: "Pilates", value: "PILATES" },
  { display: "Yoga", value: "YOGA" },
  { display: "Functional Fitness", value: "FUNCTIONAL_FITNESS" },
  { display: "Martial Arts", value: "MARTIAL_ARTS" },
  { display: "Spinning", value: "SPINNING" },
  { display: "Biking", value: "BIKING" },
  { display: "Swimming", value: "SWIMMING" },
  { display: "Running", value: "RUNNING" },
  { display: "Jogging", value: "JOGGING" },
  { display: "Triathlon Competitor", value: "TRIATHLON_COMPETITOR" },
  { display: "Marathon Competitor", value: "MARATHON_COMPETITOR" },
  { display: "Iron Man Competitor", value: "IRON_MAN_COMPETITOR" },
];

export const foodSubGroupCodes: FOODSUBGROUPCODE[] = [
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Cereals and cereal products",
    code: "A",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Flours, grains and starches",
    code: "AA",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Sandwiches",
    code: "AB",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Rice",
    code: "AC",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Pasta",
    code: "AD",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Pizzas",
    code: "AE",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Breads",
    code: "AF",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Rolls",
    code: "AG",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Breakfast cereals",
    code: "AI",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Infant cereal foods",
    code: "AK",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Biscuits",
    code: "AM",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Cakes",
    code: "AN",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Pastry",
    code: "AO",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Buns and pastries",
    code: "AP",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Puddings",
    code: "AS",
  },
  {
    foodGroup: "Cereals and cereal products",
    subGroup: "Savouries",
    code: "AT",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Milk and milk products",
    code: "B",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Cows milk",
    code: "BA",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Breakfast milk",
    code: "BAB",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Skimmed milk",
    code: "BAE",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Semi-skimmed milk",
    code: "BAH",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Whole milk",
    code: "BAK",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Channel Island milk",
    code: "BAN",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Processed milks",
    code: "BAR",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Other milks",
    code: "BC",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Infant formulas",
    code: "BF",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Whey-based modified milks",
    code: "BFD",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Non-whey-based modified milks",
    code: "BFG",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Soya-based modified milks",
    code: "BFJ",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Follow-on formulas",
    code: "BFP",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Milk-based drinks",
    code: "BH",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Creams",
    code: "BJ",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Fresh creams (pasteurised)",
    code: "BJC",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Frozen creams (pasteurised)",
    code: "BJF",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Sterilised creams",
    code: "BJL",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "UHT creams",
    code: "BJP",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Imitation creams",
    code: "BJS",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Cheeses",
    code: "BL",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Yogurts",
    code: "BN",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Whole milk yogurts",
    code: "BNE",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Low fat yogurts",
    code: "BNH",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Other yogurts",
    code: "BNS",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Ice creams",
    code: "BP",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Puddings and chilled desserts",
    code: "BR",
  },
  {
    foodGroup: "Milk and milk products",
    subGroup: "Savoury dishes and sauces",
    code: "BV",
  },
  {
    foodGroup: "Eggs",
    subGroup: "Eggs",
    code: "C",
  },
  {
    foodGroup: "Eggs",
    subGroup: "Eggs",
    code: "CA",
  },
  {
    foodGroup: "Eggs",
    subGroup: "Egg dishes",
    code: "CD",
  },
  {
    foodGroup: "Eggs",
    subGroup: "Savoury egg dishes",
    code: "CDE",
  },
  {
    foodGroup: "Eggs",
    subGroup: "Sweet egg dishes",
    code: "CDH",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Vegetables",
    code: "D",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Potatoes",
    code: "DA",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Early potatoes",
    code: "DAE",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Main crop potatoes",
    code: "DAM",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Chipped old potatoes",
    code: "DAP",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Potato products",
    code: "DAR",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Beans and lentils",
    code: "DB",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Peas",
    code: "DF",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Vegetables, general",
    code: "DG",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Vegetables, dried",
    code: "DI",
  },
  {
    foodGroup: "Vegetables",
    subGroup: "Vegetable dishes",
    code: "DR",
  },
  {
    foodGroup: "Fruit",
    subGroup: "Fruit",
    code: "F",
  },
  {
    foodGroup: "Fruit",
    subGroup: "Fruit, general",
    code: "FA",
  },
  {
    foodGroup: "Fruit",
    subGroup: "Fruit juices",
    code: "FC",
  },
  {
    foodGroup: "Nuts and seeds",
    subGroup: "Nuts and seeds",
    code: "G",
  },
  {
    foodGroup: "Nuts and seeds",
    subGroup: "Nuts and seeds, general",
    code: "GA",
  },
  {
    foodGroup: "Herbs and spices",
    subGroup: "Herbs and spices",
    code: "H",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "Fish and fish products",
    code: "J",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "White fish",
    code: "JA",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "Fatty fish",
    code: "JC",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "Crustacea",
    code: "JK",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "Molluscs",
    code: "JM",
  },
  {
    foodGroup: "Fish and fish products",
    subGroup: "Fish products and dishes",
    code: "JR",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Meat and meat products",
    code: "M",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Meat",
    code: "MA",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Bacon",
    code: "MAA",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Beef",
    code: "MAC",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Lamb",
    code: "MAE",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Pork",
    code: "MAG",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Veal",
    code: "MAI",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Poultry",
    code: "MC",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Chicken",
    code: "MCA",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Duck",
    code: "MCC",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Goose",
    code: "MCE",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Grouse",
    code: "MCG",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Partridge",
    code: "MCI",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Pheasant",
    code: "MCK",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Pigeon",
    code: "MCM",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Turkey",
    code: "MCO",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Game",
    code: "ME",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Hare",
    code: "MEA",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Rabbit",
    code: "MEC",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Venison",
    code: "MEE",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Offal",
    code: "MG",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Burgers and grillsteaks",
    code: "MBG",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Meat products",
    code: "MI",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Other meat products",
    code: "MIG",
  },
  {
    foodGroup: "Meat and meat products",
    subGroup: "Meat dishes",
    code: "MR",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Fats and oils",
    code: "O",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Spreading fats",
    code: "OA",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Animal fats",
    code: "OB",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Oils",
    code: "OC",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Non-animal fats",
    code: "OE",
  },
  {
    foodGroup: "Fats and oils",
    subGroup: "Cooking fats",
    code: "OF",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Beverages",
    code: "P",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Powdered drinks, essences and infusions",
    code: "PA",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Powdered drinks and essences",
    code: "PAA",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Infusions",
    code: "PAC",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Soft drinks",
    code: "PC",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Carbonated drinks",
    code: "PCA",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Squash and cordials",
    code: "PCC",
  },
  {
    foodGroup: "Beverages",
    subGroup: "Juices",
    code: "PE",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Alcoholic beverages",
    code: "Q",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Beers",
    code: "QA",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Ciders",
    code: "QC",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Wines",
    code: "QE",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Fortified wines",
    code: "QF",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Vermouths",
    code: "QG",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Liqueurs",
    code: "QI",
  },
  {
    foodGroup: "Alcoholic beverages",
    subGroup: "Spirits",
    code: "QK",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Sugars, preserves and snacks",
    code: "S",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Sugars, syrups and preserves",
    code: "SC",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Confectionery",
    code: "SE",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Chocolate confectionery",
    code: "SEA",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Non-chocolate confectionery",
    code: "SEC",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Savoury snacks",
    code: "SN",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Potato-based snacks",
    code: "SNA",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Potato and mixed cereal snacks",
    code: "SNB",
  },
  {
    foodGroup: "Sugars, preserves and snacks",
    subGroup: "Non-potato snacks",
    code: "SNC",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Soups, sauces and miscellaneous foods",
    code: "W",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Soups",
    code: "WA",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Homemade soups",
    code: "WAA",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Canned soups",
    code: "WAC",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Packet soups",
    code: "WAE",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Sauces",
    code: "WC",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Dairy sauces",
    code: "WCD",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Salad sauces, dressings and pickles",
    code: "WCG",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Non-salad sauces",
    code: "WCN",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Pickles and chutneys",
    code: "WE",
  },
  {
    foodGroup: "Soups, sauces and miscellaneous foods",
    subGroup: "Miscellaneous foods",
    code: "WY",
  },
];

export const fitnessTrainersSpecialities: DROPDOWNTYPETAGDISPLAY[] = [
  { display: "General Fitness", value: "GENERAL_FITNESS" },
  { display: "Zumba Instructor", value: "ZUMBA_INSTRUCTOR" },
  { display: "Aerobics Instructor", value: "AEROBICS_INSTRUCTOR" },
  { display: "CrossFit Coach", value: "CROSSFIT_COACH" },
  { display: "TRX Coach", value: "TRX_COACH" },
  {
    display: "Strength and Conditioning Coach",
    value: "STRENGTH_AND_CONDITIONING_COACH",
  },
  { display: "Power Building Coach", value: "POWER_BUILDING_COACH" },
  { display: "Athletic Therapy", value: "ATHLETIC_THERAPY" },
  { display: "Bodybuilding Coach", value: "BODYBUILDING_COACH" },
  { display: "Prep Coaching", value: "PREP_COACHING" },
  { display: "Posing Coach", value: "POSING_COACH" },
  { display: "Calisthenics Coaching", value: "CALISTHENICS_COACHING" },
  { display: "Pilates Instructor", value: "PILATES_INSTRUCTOR" },
  { display: "Yoga Instructor", value: "YOGA_INSTRUCTOR" },
  { display: "Functional Fitness", value: "FUNCTIONAL_FITNESS" },
  {
    display: "Body Transformation Coaching",
    value: "BODY_TRANSFORMATION_COACHING",
  },
  { display: "Personal Training", value: "PERSONAL_TRAINING" },
  { display: "Martial Arts Coaching", value: "MARTIAL_ARTS_COACHING" },
  { display: "Cardiac Rehabilitation", value: "CARDIAC_REHABILITATION" },
  { display: "Mobility Exercise Therapy", value: "MOBILITY_EXERCISE_THERAPY" },
  { display: "Spinning Coaching", value: "SPINNING_COACHING" },
  { display: "Aquatic Exercise Therapy", value: "AQUATIC_EXERCISE_THERAPY" },
  { display: "Triathlon Coaching", value: "TRIATHLON_COACHING" },
  { display: "Marathon Coaching", value: "MARATHON_COACHING" },
  { display: "Iron Man Coaching", value: "IRON_MAN_COACHING" },
];

// Camp Fit Categories

// 1.	Endurance
// 2.	Strength
// 3.	Balance
// 5.	Home Workouts
// 6.	Diet
// 7.	Meal prep
// 8.	Nutrition
// 9.	Macro & Micro Nutrients
// 10.	Mindset
// 11.	Rehabilitation
// 12.	Competition prep
// 13.	Body Composition
// 14.	Muscular Fitness
// 15.	Cardiorespiratory Endurance
// 16.	Flexibility
// 17.	Balance
// 18.	Coordination
// 19.	Power
// 20.	Agility
// 21.	Metabolic Fitness
// 22.	Health Related Fitness
// 23.	Body Composition
// 24.	Body recompositing
// 25.	Muscular Strength
// 26.	Muscular Endurance
// 27.	BMI
// 28.	Agility
// 29.	Marital Arts
// 30.	Outdoor Fitness
// 31.	Injury Prevention
// 32.	Insulin Sensitivity
// 33.	Sport Specific
// 34.	Wellness
// 35.	Training principles
// 36.	Progressive overload
// 37.	Anaerobic Fitness
// 38.	Aerobic Fitness
// 39.	Conventional Training Methods
// 40.	Supersets
// 41.	Drop sets
// 42.	Body Splits
// 43.	Full Body Routine
// 44.	Supplements
// 45.	Recovery

export const fitCampCategories: DROPDOWNTYPETAGDISPLAY[] = [
  { value: "AEROBIC_FITNESS", display: "Aerobic Fitness" },
  { value: "AGILITY", display: "Agility" },
  { value: "ANAEROBIC_FITNESS", display: "Anaerobic Fitness" },
  { value: "BALANCE", display: "Balance" },
  { value: "BMI", display: "BMI" },
  { value: "BODY_COMPOSITION", display: "Body Composition" },
  { value: "BODY_RECOMPOSITION", display: "Body recompositing" },
  { value: "BODY_SPLITS", display: "Body Splits" },
  {
    value: "CARDIORESPIRATORY_ENDURANCE",
    display: "Cardiorespiratory Endurance",
  },
  { value: "COMPETITION_PREP", display: "Competition prep" },
  {
    value: "CONVENTIONAL_TRAINING_METHODS",
    display: "Conventional Training Methods",
  },
  { value: "COORDINATION", display: "Coordination" },
  { value: "DIET", display: "Diet" },
  { value: "DROP_SETS", display: "Drop sets" },
  { value: "ENDURANCE", display: "Endurance" },
  { value: "FLEXIBILITY", display: "Flexibility" },
  { value: "FULL_BODY_ROUTINE", display: "Full Body Routine" },
  { value: "HEALTH_RELATED_FITNESS", display: "Health Related Fitness" },
  { value: "HOME_WORKOUTS", display: "Home Workouts" },
  { value: "INJURY_PREVENTION", display: "Injury Prevention" },
  { value: "INSULIN_SENSITIVITY", display: "Insulin Sensitivity" },
  { value: "MACRO_MICRO_NUTRIENTS", display: "Macro & Micro Nutrients" },
  { value: "MARITAL_ARTS", display: "Marital Arts" },
  { value: "MEAL_PREP", display: "Meal prep" },
  { value: "METABOLIC_FITNESS", display: "Metabolic Fitness" },
  { value: "MINDSET", display: "Mindset" },
  { value: "MUSCULAR_ENDURANCE", display: "Muscular Endurance" },
  { value: "MUSCULAR_FITNESS", display: "Muscular Fitness" },
  { value: "MUSCULAR_STRENGTH", display: "Muscular Strength" },
  { value: "NUTRITION", display: "Nutrition" },
  { value: "OUTDOOR_FITNESS", display: "Outdoor Fitness" },
  { value: "POWER", display: "Power" },
  { value: "PROGRESSIVE_OVERLOAD", display: "Progressive overload" },
  { value: "RECOVERY", display: "Recovery" },
  { value: "REHABILITATION", display: "Rehabilitation" },
  { value: "SPORT_SPECIFIC", display: "Sport Specific" },
  { value: "STRENGTH", display: "Strength" },
  { value: "SUPERSETS", display: "Supersets" },
  { value: "SUPPLEMENTS", display: "Supplements" },
  { value: "TRAINING_PRINCIPLES", display: "Training principles" },
  { value: "WELLNESS", display: "Wellness" },
];

export const categoryType: DROPDOWNTYPE[] = [
  { value: "challenge", type: "Challenge", },
  { value: "company", type: "Company" },
  { value: "form", type: "Form" },
];

export const QuestionnaireAnswerType: DROPDOWNTYPE[] = [
  { value: "shortAnswer", type: "Short Answer" },
  { value: "paragraph", type: "Paragraph" },
  { value: "multipleChoice", type: "Multiple Choice" },
  { value: "dropdown", type: "Dropdown" }, 
  // { value: "linearScale", type: "Linear Scale" },
  // { value: "multipleChoiceGrid", type: "Multiple-choice Grid" },
];

// export const categoryType: DROPDOWNTYPE[] = [
//   { value: "challenge", type: "Challenge"},
//   { value: "company", type: "Company" },
//   { value: "form", type: "Form" },
// ];

// export const answerType: DROPDOWNTYPE[] = [
//   { value: "shortAnswer", type: "Short Answer"},
//   { value: "paragraph", type: "Paragraph" },
//   { value: "multipleChoice", type: "Multiple Choice" },
//   { value: "dropdown", type: "Dropdown" },
//   { value: "linearScale", type: "Linear Scale" },
//   { value: "multipleChoiceGrid", type: "Multiple-choice Grid" },
// ];

declare interface USERROLETYPE {
  value: string;
  role: string;
}

export const userRoleType: USERROLETYPE[] = [
  { value: "Admin", role: "System Administrator" },
  { value: "User", role: "User" },
  { value: "Coach", role: "Coach" },
];

// from 1kg to 200kg
export const clientPortalWeights: DROPDOWNTYPENUMBERDISPLAY[] = [];

// Generate weight options from 1 kg to 500 kg
for (let kg = 1; kg <= 500; kg++) {
  // Convert the kg value to a string and add " kg" to the display value
  const weightOption = {
    value: kg,
    // display: `${kg} kg`,
    display: `${kg}`,
  };

  // Add the weight option to the array
  clientPortalWeights.push(weightOption);
}

const matching = [
  { user: "GENERAL_FITNESS", coach: "GENERAL_FITNESS" },
  { user: "ZUMBA", coach: "ZUMBA_INSTRUCTOR" },
  { user: "AEROBICS", coach: "AEROBICS_INSTRUCTOR" },
  { user: "CROSSFIT", coach: "CROSSFIT_COACH" },
  { user: "TRX_TRAINING", coach: "TRX_COACH" },
  { user: "POWER_LIFTING", coach: "POWER_BUILDING_COACH" },
  { user: "BODYBUILDING", coach: "BODYBUILDING_COACH" },
  { user: "MEN'S_PHYSIQUE", coach: "BODYBUILDING_COACH" },
  { user: "WOMEN'S_PHYSSIQUE", coach: "BODYBUILDING_COACH" },
  { user: "BIKINI_COMPETITOR", coach: "BODYBUILDING_COACH" },
  { user: "CALISTHENICS", coach: "CALISTHENICS_COACHING" },
  { user: "PILATES", coach: "PILATES_INSTRUCTOR" },
  { user: "YOGA", coach: "YOGA_INSTRUCTOR" },
  { user: "FUNCTIONAL_FITNESS", coach: "FUNCTIONAL_FITNESS" },
  { user: "MARTIAL_ARTS", coach: "MARTIAL_ARTS_COACHING" },
  { user: "SPINNING", coach: "SPINNING_COACHING" },
  { user: "BIKING", coach: "BODYBUILDING_COACH" },
  { user: "SWIMMING", coach: "AQUATIC_EXERCISE_THERAPY" },
  { user: "RUNNING", coach: "MARATHON_COACHING" },
  { user: "JOGGING", coach: "MARATHON_COACHING" },
  { user: "TRIATHLON_COMPETITOR", coach: "TRIATHLON_COACHING" },
  { user: "MARATHON_COMPETITOR", coach: "MARATHON_COACHING" },
  { user: "IRON_MAN_COACHING", coach: "IRON_MAN_COACHING" },
];

const missing = [
  "BODY_TRANSFORMATION_COACHING",
  "PREP_COACHING",
  "POSING_COACH",
  "ATHLETIC_THERAPY",
  "CARDIAC_REHABILITATION",
  "MOBILITY_EXERCISE_THERAPY",
  "STRENGTH_AND_CONDITIONING_COACH",
  "PERSONAL_TRAINING",
];

export enum UserMembership {
  Free = 'Free',
  Pro = 'Pro',
  Advanced = 'Advanced',
}



// <div appDynamicScript>
//     <div src="https://cloozoai.github.io/1701722313806x401876924925214700/Flex.js" my-custom-attr="Cardio Flourish Bot">
//         Cardio Flourish Bot
//     </div>
// </div>


// Matching Fitness Enthusiasts Interests to Fitness Trainers Specialities
// const missing = [
//   [ 'GENERAL_FITNESS', 'GENERAL_FITNESS' ],
//   [ 'ZUMBA', 'ZUMBA_INSTRUCTOR' ],
//   [ 'AEROBICS', 'AEROBICS_INSTRUCTOR' ],
//   [ 'CROSSFIT', 'CROSSFIT_COACH' ],
//   [ 'TRX_TRAINING', 'TRX_COACH' ],
//   [ 'POWER_LIFTING', 'POWER_BUILDING_COACH' ],
//   [ 'BODYBUILDING', 'BODYBUILDING_COACH' ],
//   [ 'MEN\'S_PHYSIQUE', 'BODYBUILDING_COACH' ],
