import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor() {}

  public url = "https://cardioflourish.com/api/"; //Digital Ocean Deployment
  //public url = "http://localhost:8080/"; //Local Deployment
  // public url = 'http://192.168.1.11:8080/'; //Local Deployment
  public domain = "https://cardioflourish.com/";
}
