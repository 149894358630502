import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";

@Injectable()
export class FileDownloaderProvider {
  constructor() {}

  async saveFile(fileName: string, data: Blob) {
    const base64Data = (await this.convertBlobToBase64(data)) as string;

    const saved = await Filesystem.writeFile({
      // path: fileName,
      path:
        Capacitor.getPlatform() == "android"
          ? "CardioFlourish/" + fileName
          : fileName,
      data: base64Data,
      directory: Directory.Documents,
      // encoding: Encoding.UTF8,
      recursive: true,
    });

    // helper function
  }

  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
}
