import { AvatarModule } from "ngx-avatar";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NavbarComponent } from "./navbar.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { JoyrideModule } from "ngx-joyride";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    AvatarModule,
    NgbDropdownModule,
    JoyrideModule.forChild(),
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
})
export class NavbarModule {}
